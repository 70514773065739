<template>
  <div style="padding: 10px 0">
    这是一个新的页面  item1
  </div>
</template>

<script>
export default {
  name: "Item1",
  data() {
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style>

</style>
